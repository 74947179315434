<template>
  <section class="container">
    <slot name="prepend-button" />
    <SharedKitButton
      class="button"
      :color-preset="MainPageButtonPreset.TAB"
      :size-preset="changeXL(SizePresets.M, SizePresets.S)"
      :type-preset="TypePresets.ROUND"
      :text="$t('mainPage.howItWorksPopup.button')"
      @click="handleClick"
    >
      <template #prepend>
        <SvgoInfoIcon />
      </template>
    </SharedKitButton>

    <MainPageHowItWorksModalPopup v-model:is-open="showPopup" :bg-color="styleVariables.popupBackgroundColor">
      <div class="popup">
        <div class="popup__logo">
          <NuxtImg v-if="props.logo" :width="changeXL(360, 224)" :height="changeXL(164, 100)" :src="props.logo" />
        </div>
        <div class="items-container">
          <div class="item">
            <div class="item__image">
              <NuxtImg
                v-if="props.popupData.image1"
                :width="changeXL(80, 64)"
                :height="changeXL(80, 64)"
                :src="props.popupData.image1"
              />
            </div>
            <div class="item__info">
              <I18nT keypath="mainPage.howItWorksPopup.instruction1">
                <template #eventName>
                  <span class="item__link" @click="goToAndScroll('event')">{{ props.eventFullName }}</span>
                </template>
                <template #coinImg>
                  <NuxtImg v-if="props.eventCoinIcon" class="item__coin" :src="props.eventCoinIcon" />
                </template>
              </I18nT>
            </div>
          </div>
          <div class="item">
            <div class="item__image">
              <NuxtImg
                v-if="props.popupData.image2"
                :width="changeXL(80, 64)"
                :height="changeXL(80, 64)"
                :src="props.popupData.image2"
              />
            </div>
            <div class="item__info">
              <I18nT keypath="mainPage.howItWorksPopup.instruction2">
                <template #coinImg>
                  <NuxtImg v-if="props.eventCoinIcon" class="item__coin" :src="props.eventCoinIcon" />
                </template>
                <template #coinName>
                  <span class="item__link" @click="goToAndScroll('coin')">{{ coinCasesName }}</span>
                </template>
              </I18nT>
            </div>
          </div>
          <div class="item">
            <div class="item__image">
              <NuxtImg
                v-if="props.popupData.image4"
                :width="changeXL(80, 64)"
                :height="changeXL(80, 64)"
                :src="props.popupData.image4"
              />
            </div>
            <div class="item__info">
              <I18nT keypath="mainPage.howItWorksPopup.instruction3">
                <template #coinImg>
                  <NuxtImg v-if="props.eventCoinIcon" class="item__coin" :src="props.eventCoinIcon" />
                </template>
              </I18nT>
            </div>
          </div>
          <div class="item">
            <div class="item__image">
              <NuxtImg
                v-if="props.popupData.image3"
                :width="changeXL(80, 64)"
                :height="changeXL(80, 64)"
                :src="props.popupData.image3"
              />
            </div>
            <div class="item__info">
              <I18nT keypath="mainPage.howItWorksPopup.instruction4">
                <template #level15>
                  <span class="item__arcana">15</span>
                </template>
                <template #level30>
                  <span class="item__arcana">30</span>
                </template>
                <template #level40>
                  <span class="item__arcana">40</span>
                </template>
                <template #arcana>
                  <span class="item__arcana">{{ $t('mainPage.howItWorksPopup.arcana') }}</span>
                </template>
                <template #immortal>
                  <span class="item__immortal">{{ $t('mainPage.howItWorksPopup.immortal') }}</span>
                </template>
              </I18nT>
            </div>
          </div>
          <div class="item">
            <div class="item__image">
              <NuxtImg
                v-if="props.popupData.image5"
                :width="changeXL(80, 64)"
                :height="changeXL(80, 64)"
                :src="props.popupData.image5"
              />
            </div>
            <div class="item__info">
              <I18nT keypath="mainPage.howItWorksPopup.instruction5">
                <template #coinImg>
                  <NuxtImg v-if="props.eventCoinIcon" class="item__coin" :src="props.eventCoinIcon" />
                </template>
              </I18nT>
            </div>
          </div>
          <div class="item">
            <div class="item__image">
              <NuxtImg
                v-if="props.popupData.image6"
                :width="changeXL(80, 64)"
                :height="changeXL(80, 64)"
                :src="props.popupData.image6"
              />
            </div>
            <div class="item__info">{{ $t('mainPage.howItWorksPopup.instruction6') }}</div>
          </div>
        </div>

        <SharedKitButton
          :color-preset="EventButtonPreset(button.color, button.hover)"
          :size-preset="changeXL(SizePresets.L, SizePresets.S)"
          :type-preset="TypePresets.ROUND"
          class="popup__button"
          :text="$t('mainPage.howItWorksPopup.close')"
          @click="showPopup = !showPopup"
        />
      </div>
    </MainPageHowItWorksModalPopup>
  </section>
</template>
<script setup lang="ts">
import type { IHowItWorksPopupProps } from './HowItWorksPopup.types';
import { MainPageButtonPreset, EventButtonPreset } from '~/features/mainPage/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { MainPageEvents } from '~/repository/amplitude/events/mainPage';

const {
  $projectConfig: {
    redirects: { newCases },
  },
} = useNuxtApp();

const localeRoute = useLocaleRoute();

const props = defineProps<IHowItWorksPopupProps>();

const styleVariables = computed(() => ({
  popupBackgroundColor: GlobalUtils.CSS.getBackgroundColor(props.popupData.popupGradient),
}));

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const showPopup = ref(false);

const goToAndScroll = async (pathName: string) => {
  showPopup.value = false;
  await navigateTo(localeRoute(newCases)?.path + `#${pathName}-cases`);
};

const handleClick = () => {
  MainPageEvents.infoPopupClicked();
  showPopup.value = !showPopup.value;
};
</script>

<style scoped lang="scss" src="./HowItWorksPopup.scss"></style>
